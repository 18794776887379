// Fonts
//@import url('https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css');
//@import url('http://fonts.cdnfonts.com/css/gotham');
@import "public/fonts/gotham/stylesheet";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap';

// Icon Fontawesome
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Responsive
@import "responsive";

// Owl Carousel
@import '~owl.carousel/dist/assets/owl.carousel.min.css';
@import '~owl.carousel/dist/assets/owl.theme.default.min.css';
@import '~owl.carousel';

//npm install --save owl.carousel

.imgPreview img {
    padding: 8px;
    max-width: 200px;
}

body {
    font-family: 'Gotham Book';
    font-weight: normal;
    font-style: normal;
    overflow-x: hidden;
}

h1 {
    font-size: 2.35rem;
    font-weight: 520;
    color: #3c3c3c;
}

p {
    color: #838383;
    font-weight: 150;
}

p i{ // cor dos icones endereco da Lojas
    color: $Secon-color;
}

.vh-90{
    height: 90vh!important;
}

.back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: none;
    border-radius: 0px;
    background-color: #e9e0db;
    z-index: 999;
}

//Btns Circles
.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}
.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
}

.btn{
    font-weight: 700!important;
}

/*-----------------------------------------EFEITO-NAV------------------------------------------------------------------*/
.site-navbar {
    margin-bottom: 0px;
    z-index: 1999;
    top: 0;
    width: 100%;
    padding: 1rem;
    background: linear-gradient(rgba(0, 0, 0, 0.78), rgba(0, 0, 0, 0.02));
}

.site-navbar .toggle-button {
    position: absolute;
    right: 0px;
}

.site-navbar .site-logo {
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.site-navbar .site-logo a {
    text-transform: uppercase;
    color: $body-bg;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.sticky-wrapper .site-navbar {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.sticky-wrapper .site-navbar {
    background: $body-bg;
}

.sticky-wrapper .site-navbar ul li a {
    color: rgba(255, 255, 255, 0.7) !important;
}

.sticky-wrapper .site-navbar ul li a.active {
    color: $body-bg !important;
}

.sticky-wrapper.is-sticky .site-navbar {
    background: $body-bg;
    -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
}

.sticky-wrapper.is-sticky .site-navbar ul li a {
    color: #000 !important;
}

.site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid white !important;
}

.site-navbar .site-navigation .site-menu {
    margin: 0;
    padding: 0;
    margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu a {
    text-decoration: none !important;
    display: inline-block;
}

.site-navbar .site-navigation .site-menu > li {
    display: inline-block;
}

.site-navbar .site-navigation .site-menu > li > a {

    margin-left: 16px;
    margin-right: 15px;
    padding: 6px 0px;
    color: rgba(255, 255, 255, 0.95) !important;
    display: inline-block;
    text-decoration: none !important;
    position: relative;
    font-weight: 250;
}

.site-navbar .site-navigation .site-menu > li > a:before {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .3s transform ease;
    -o-transition: .3s transform ease;
    transition: .3s transform ease;
    background: $Secon-color;
}

.site-navbar .site-navigation .site-menu > li > a:hover {
    color: #ccc3bf !important;
}

.site-navbar .site-navigation .site-menu > li > a:hover:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.site-navbar .site-navigation .site-menu > li.active > a {
    color: $body-bg !important;
}

.site-navbar .site-navigation .site-menu > li.active > a:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.site-navbar .site-navigation .site-menu .has-children {
    position: relative;
}

.site-navbar .site-navigation .site-menu .has-children > a {
    position: relative;
    padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children > a:before {
    position: absolute;
    content: "\e313";
    font-size: 16px;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
    position: absolute;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    display: none;
    bottom: 100%;
    left: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: $body-bg;
    border-width: 10px;
    margin-left: -10px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
    text-transform: none;
    letter-spacing: normal;
    -webkit-transition: 0s all;
    -o-transition: 0s all;
    transition: 0s all;
    color: #000 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 200px;

}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
    padding: 9px 20px;
    display: block;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
    background: $body-bg;
    color: $body-bg;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
    content: "\e315";
    right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
    left: 100%;
    top: 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:active > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:focus > a {
    background: $body-bg;
    color: $body-bg;
}

.site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
    cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
}

.site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
    position: absolute;
    right: 0px;
    top: 10px;
    z-index: 20;
    width: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
    font-size: 12px;
    z-index: 20;
    font-family: "icomoon";
    content: "\f078";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
    -ms-transform: translate(-50%, -50%) rotate(-180deg);
    transform: translate(-50%, -50%) rotate(-180deg);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap > li {
    display: block;
    position: relative;
    float: left;
    width: 100%;
}

.site-mobile-menu .site-nav-wrap > li > a {
    padding-left: 20px;
    font-size: 20px;
}

.site-mobile-menu .site-nav-wrap > li > ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.site-mobile-menu .site-nav-wrap > li > ul > li {
    display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > a {
    padding-left: 40px;
    font-size: 16px;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
    padding: 0;
    margin: 0;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
    display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
    font-size: 16px;
    padding-left: 60px;
}

.site-mobile-menu .site-nav-wrap[data-class="social"] {
    float: left;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 5em;
}

.site-mobile-menu .site-nav-wrap[data-class="social"] > li {
    width: auto;
}

.site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
    padding-left: 15px !important;
}

/*-----------------------------------------------------------------------------------------------------*/


.fixed-top {
    top: -40px;
    transform: translateY(40px);
    transition: transform .3s;
}


nav {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 25.2px;
    text-transform: none;


}

.navbar-dark .navbar-nav .nav-link {
    color: $body-bg;

}

.navbar-dark .navbar-nav .nav-link:link {
    color: $body-bg;

}

.navbar-dark .navbar-nav .nav-link:hover {
    color: $Secon-color;

}

.navbar-dark .navbar-nav .nav-link:active {
    color: $Secon-color;

}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .55);
    border-color: rgba(255, 255, 255, 0.2);
}

header {

    background: black;

}

#containerMenu {

    max-width: 1440px;

}

/*-----------------------------------------------------barra de pesquisa ---------------------------------------------------*/

.fixed-top {

    background: rgba(0, 0, 0, 0.37);

}

#masthead-search{

    border-radius: 20px;
    background: rgba(255, 255, 255, 0);
    height: calc(1.0em + 0.75rem + 2px);
    margin-top: 3px;
    margin-left: 0px;
    width: 100%;
    max-width: 14em;
    position: relative;
    -webkit-appearance: none;
}


.estiloCampo {

    color: $body-bg;
    background: transparent;
    border: none;
    border-color: $body-bg;
    border-style: solid;
    border-width: 1px;
    border-radius: 50px;
    outline: none;
    width: 90%;
    margin-left: 14px;
    padding-left: 8px;
    padding-right: 27px;
    transition: border-color 0.3s ease;
    -webkit-appearance: none;
}

.estiloCampo:hover {

    background: rgba(255, 255, 255, 0.19);
}

#masthead-search button {
    background: none;
    border: none;
    color: $body-bg;
    font-size: 0.7em;
    padding: 0;
    position: absolute;
    top: 46%;
    right: 10%;
    transform: translateY(-50%);
    -webkit-appearance: none;
}

.icoReSocial {

    margin-left: 0px;
    margin-right: 0;
    width: 36px;
    height: 37px;
    padding-left: -10px;
}

/*--- !*!*ICONE MENU!*!  ---*/

li a i {

    font-size: 17px;

}


/*---------------------------------------------------------------------*/

/*-----------------------------------------------------Footer---------------------------------------------------*/

footer h4 {
    color: $Gray;
    font-weight: bold;
    margin-top: 15px;

}

.nav-link {
    color: $body-bg;
}

footer .navbar-nav a {

    color: $body-bg;
    font-size: 18px;
    text-decoration: none;
}

footer .navbar-nav a:hover {
    color: $Gray;
    font-size: 18px;
}

footer ul {
    list-style: none;

}

footer ul li {
    float: right;
    margin-left: 5px;

}

.villa-search-footer{
    border-radius: 20px;
    background: rgba(255, 255, 255, 0);
    height: calc(1.0em + 0.75rem + 2px);
    margin-top: 3px;
    margin-left: 0px;
    width: 100%;
    max-width: 14em;
    position: relative;
    -webkit-appearance: none;
}

.villa-search-footer button {
    background: none;
    border: none;
    color: $body-bg;
    padding: 0;
    position: absolute;
    top: 46%;
    right: 10%;
    transform: translateY(-50%);
    -webkit-appearance: none;
}

.villa-search-footer-input{
    color: $body-bg;
    background: transparent;
    border: none;
    border-color:$body-bg;
    border-style: solid;
    border-width: 1px;
    border-radius: 50px;
    outline: none;
    width: 93%;
    margin-left: 14px;
    padding-left: 8px;
    padding-right: 27px;
    transition: border-color 0.3s ease;
    -webkit-appearance: none;
}

.villa-search-footer-input:hover {
    background: rgba(255, 255, 255, 0.19);

}

/*---------------------------------------- MENU PESQUISA SELECT ----------------------------------------------------------*/

.dropdown .dropdown-menu {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
}


.dropdown-menu-invisivel {
    display: none;
}


.dropdown:hover .dropdown-menu {
    max-height: 300px;
    opacity: 1;
}


.listaDePesquisa {

    //margin-top: 2px;
    font-size: 11px;
    height: 19px;
    width: 100%;
    text-align: end;

}


#navbarDropdown {
    width: 100%;
    background: rgb(255, 255, 255);

}

#dropdownMenuLink {

    height: 26px;
    padding-top: 0;
    margin-right: 17px;
}

/*-----------------------------------BOTAO BUSCA ----------------------*/

.estiloBotaoBusca .btn-outline-light{
    width: 100%;
    height: 38px;
    border-radius: 20px;
    border-color: $Secon-color;
    color: $Secon-color;
    transition: all 500ms linear;
    font-size: 14px;

}

.estiloBotaoBusca .btn-outline-light:hover{

    background-color: $Secon-color;
    transition: all 500ms linear;
    color: $body-bg;

}


.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: $body-bg;
    border-color: $Gray;
    width: 140px;

}

.estiloBotaoBusca {

    margin-right: auto;
    margin-left: auto;
    width: 177px;
    padding-top: 0px;
    margin-top: 20px;
    margin-bottom: 15px;


}

.botao {
    width: 100%;

}

.dropdown-toggle::after {

    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}


/*------------------------------Select----------------------------------------- */


.form-control {
    display: block;
    width: 90%;
    height: calc(1.3em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    margin-bottom: 3px;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.6;
    color: $Gray;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0);
    border-bottom: 1px solid $Gray;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*--------------------------------------------------------- CARROSEL---------------------------------------------------------*/


.carousel-indicators {
    bottom: 55px;
    height: 20px;
}

.carousel-indicators > button {
    width: 0px;
    height: 8px;
    margin-left: 13px;
    border-radius: 50%;
    border: none;
    padding: 5px;

}

.carousel-indicators .active {
    background-color: $Gray;

}

.carousel-indicators li {

    transition: opacity 0.3s ease;
}


.barra {
    height: 8px;
    background-color: $Secon-color;
    width: 0%;
    margin: -8px 0px 0px 0px;
    border: none;
    z-index: 1;
    position: relative;
}

.barra.anima {
    -webkit-transition: width 9.10s linear;
    -moz-transition: width 9.10s linear;
    -o-transition: width  9.10s linear;
    transition: width 9.10s linear;
}

.carousel-item {

    //transition: transform 0.2s ease-in-out;

}

//Products View
.banner-image {
    background-size: cover;
    height: 100vh;
    width: 100vw;
    margin: 0px

}



//---------------------------------------------------------- SIMULADOR DE AMBIENTE-----------------------------------------------------

.sectionSimulador, .especificadorVilla{
    background: $prima-color;
}

//------------------ BORDA DO BOTAO SIMULADOR =-----------------------
//-------------------------------------------------View Abouts--------------------------------------------------------

.villa-about-hr {
    color: #B19C94 !important;
    border-width: 2px;
}

.villa-about-color {
    background-color: #E9E0DC;
}

.villa-about-icon-color{
    padding: 12px;
    border-radius: 50%;
    border: 2px solid #B19C94 ;
    color: #B19C94;
}

//----------------------------------------------------------------------------------------------------------------------

.cor-borda{

    border-bottom: 2px solid $Secon-color;
    width: 78px;
    margin-left: 50%;
    margin-right: auto;
}

.cor-borda2{

    border-bottom: 2px solid $Secon-color;
    width: 80px;
    margin-left: 47%;
    margin-right: auto;
}

//-----------------------------BOTAO-------------------

.bntPdrCol .btn-outline-light{

    max-width: 100px !important;
    min-width: 100px !important;
    border:1px solid #9d8c86 !important;
}

.bntPdrCol.btn-outline-light:hover{
    border:1px solid #9d8c86 !important;
}


.bntPdrCol {
    padding-right: 15px;
    padding-left: 15px;
}

.botao-padrao .btn-outline-light{

    max-width: 90px;
    min-width: 90px;
    background: $Secon-color;
    border-radius: 0px;
    height: 40px;
}

.botao-padrao .btn-outline-light:hover{

    background: $body-bg;
    color: $Secon-color;
    transition: all 500ms linear;
    border: $Secon-color;
    max-width: 90px;
    min-width: 90px;
    height: 40px;
}

.titulosPrincipais{
    font-weight: 550;
    font-size: 30px;
    letter-spacing: -0.05em;
    color:$Gray;
}

//-------------------------------------------------View Products--------------------------------------------------------

.villa-search-pro {
    height: 400px;
    background-color: $prima-color;
}

.villa-search-hr-main {
    color: $Secon-color !important;
    border-width: 2px;
}

.villa-search-main{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.villa-search-pro-input {
    color: $Secon-color !important;
    border-color: $Secon-color !important;
    background-color: transparent;
    font-weight: 600 !important;
    border: 2px solid !important;

    .selected {
        background: $body-bg;
    }

    option {
        background: $body-bg;
    }
}

//------------------------------------------- LANÇAMENTOS 2021 -------------------------------------------

//.owl-theme .owl-nav {
//    margin-top: -236px;
//    margin-left: 100%;
//    position: absolute;
//}

.owl-theme .owl-nav [class*=owl-] {
    border-radius: 50%;
    background: $Secon-color;
}

.owl-theme .owl-nav [class*=owl-]:hover{
    background: $body-bg;
    transition: all 500ms linear;
    color: black;
}
.owl-theme .owl-nav [class*=owl-] i{
    padding-left: 3px !important;
}

#owl-carousel-der{
    position: relative;
}

.owl-nav-der {
    bottom: 50% !important;
    width: 35px;
    margin-left: 100%;
    position: absolute;
}

#owl-carousel-home{
    position: relative;
}

.owl-nav-home {
    bottom: 50% !important;
    margin-left: 100%;
    position: absolute;
}

.owl-theme .owl-dots, .owl-theme .owl-nav{
    margin-top: 5px;
    margin-right: 10px;

}

#carousel-lancamentos{
    .owl-theme .owl-nav.disabled+.owl-dots{
        display: block!important;
        margin-top: 7px;
        margin-left: auto;
        margin-right: auto;
    }

    .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled{
        display: flex!important;
    }

    .owl-carousel .owl-nav button.owl-next{

        position: absolute!important;
        top: 195px;
        font-size: 40px;
        padding: 0!important;
        margin: 0!important;
        right: -38px;
}
    .owl-carousel .owl-nav button.owl-next:hover{
        background: rgba(255, 255, 255, 0) !important;
    }

    .owl-theme .owl-nav [class*=owl-]:hover {
        background: rgb(255, 255, 255) !important;
        color: #000000;
    }

}

//----------------------------------------- EFEITOS DE ZOOM NA IMG ------------------------------------

.sombra{
box-shadow: 4px 4px 5px rgba(0,0,0,0.47);
}

.zoom {
//overflow: hidden;
}

.zoom img {
max-width: 100%;
-moz-transition: all 3.3s;
-webkit-transition: all 3.3s;
transition: all 1.0s;

}

.zoom:hover img {
-moz-transform: scale(1.02);
-webkit-transform: scale(1.02);
transform: scale(1.02);
}

//-------------------------------------------------- EFEITO -----------------------------------------

.descrImg-cole{
top:0;
background: linear-gradient(rgba(0, 0, 0, 0.41), rgba(0, 0, 0, 0.01));
width: 100%;

}

.img__description_layer{

position: absolute;
top: 0px;
bottom: 16px;
left: 0px;
right: 0px;
background: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.22));
visibility: hidden;
opacity: 0;
display: flex;
align-items: center;
justify-content: center;
transition: opacity 1.0s, visibility 1.2s;
}

.img__wrap:hover .img__description_layer {
visibility: visible;
opacity: 1;
}

.img__description {
transition: .2s;
transform: translateY(1em);
color: white;
//background:$Secon-color;
margin-bottom: 43px;
}

.img__wrap:hover .img__description {
//transform: translateY(0);
}

//------------------------------------------HOVER Coleções ---------------------------------------

.col-md-4 img{
object-fit: none;

}

img{

object-fit: cover

}

.modeloHoverColecao-1{

border: 1px solid rgba(255, 255, 255, 0.45);
height: 96%;
padding: 20px;
padding-top: 2px;
margin:3% ;
}

.modeloHoverColecao-2{

border: 1px solid rgba(255, 255, 255, 0.45);
padding:0%;
margin: 1%;
min-height: 215px;

}

.modeloHoverColecaoMini-3{

border: 1px solid rgba(255, 255, 255, 0.45);
margin: 3%;
width: 94%;
padding: 5px;
min-height: 285px;

}

.modeloHoverColecaoMini-4{

border: 1px solid rgba(255, 255, 255, 0.45);
margin: 2%;
width: 94%;
padding-left: 20px;
padding-right: 20px;
min-height: 285px;

}

.textColecao{
color: white;
font-size: 14px;

}

.block-with-text {
overflow: hidden;
position: relative;
line-height: 1.5em;
max-height: 9.6em;
margin-right: -1em;
padding-right: 1em;
}

//----------------------------------------ANIMAÇAO TITULOS DAS PAGINAS-----------------------------------------------------

.cd-words-wrapper {
display: inline-block;
position: relative;
text-align: left;
//font-size: 100px;
}

.cd-words-wrapper b {
display: inline-block;
position: absolute;
white-space: nowrap;
left: 0;
top: 0;
}

.cd-words-wrapper b.is-visible {
position: relative;
}

.cd-headline.clip span {
display: inline-block;
padding: .1em 0;

}

.cd-headline.clip .cd-words-wrapper {
overflow: hidden;
vertical-align: top;

}

.cd-headline.clip b {
opacity: 0;
}

.cd-headline b.is-visible {
opacity: 1;
font-weight: 300;
}



.villa-der-input {
color: white;
border-color: #b19c94 !important;
background-color: #b19c94;
font-weight: 600 !important;
border: 2px #b19c94 !important;
}

//----------------------------------------------------Rodape-------------------------------------------


.imputFoolter {

color: black !important;
border-color: $Secon-color !important;

}


//----------------------------------------------------Admin-------------------------------------------

#sidebar-wrapper {
min-height: 100vh;
margin-left: -15rem;
-webkit-transition: margin .25s ease-out;
-moz-transition: margin .25s ease-out;
-o-transition: margin .25s ease-out;
transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
padding: 0.875rem 1.25rem;
font-size: 1.2rem;
}
#sidebar-wrapper .list-group {
width: 15rem;
}
#page-content-wrapper {
min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
margin-left: 0;
}
@media (min-width: 768px) {
#sidebar-wrapper {
margin-left: 0;
}
#page-content-wrapper {
min-width: 0;
width: 100%;
}
#wrapper.toggled #sidebar-wrapper {
margin-left: -15rem;
}
}

//----------------------------------------------------Admin-------------------------------------------

//--------------------------------------------------------UNIDADE SC --------------------------------------

.descUni {
border-left: 8px solid $prima-color;
padding-left: 12px;
}

.descUni h2 {
font-weight: 600;
color:$Gray;
max-width: 214px;
}

.descUniP p, .descUni p {
font-size: 14px;
margin-left: 3px;
}

.descUniP p i{
padding-right: 11px;
font-size: 17px;
color: $Secon-color
}

//---------------------------------------------------------- Encontre Showroom --------------------------------

.encShowroom .form-control {

width: 70%;
height: calc(2.3em + 0.75rem + 2px);
color:$Secon-color;
border:1px solid $Secon-color;

}

.btnShowroom{
width: 70%;
}

.btnShowroom input{
border: 0px;
}

//-----------------------------------------------------Image preview ---------------------------------------------------

.file-drop-area {
position: relative;
display: flex;
align-items: center;
max-width: 100%;
padding: 25px 0 0 0;
border: 1px solid rgba(255, 255, 255, 0.4);
border-radius: 3px;
transition: .2s
}

.choose-file-button {
flex-shrink: 0;
background-color: rgba(255, 255, 255, 0.04);
border: 1px solid black;
border-radius: 3px;
padding: 8px 15px;
margin-right: 10px;
font-size: 12px;
text-transform: uppercase
}

.file-message {
font-size: small;
font-weight: 300;
line-height: 1.4;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
border-bottom: 1px solid black;
}

.file-input {
position: absolute;
left: 0;
top: 0;
height: 100%;
width: 100%;
cursor: pointer;
opacity: 0
}

//---------------------------------------------------- TABELA MODAL --------------------

.table.table_modal th, .table.table_modal td{
padding: 0.4rem;
font-weight: 100;
}

.textCorTabela td{
color: #838383;

}

.table.table_modal thead th {
vertical-align: bottom;
border-bottom: 0px;
border-right: 8px solid white;
background:$Secon-color;

}

.modal-header{
padding: 0rem 1rem;
border-bottom: 0px;
}

.modal-content{
border-radius: 0rem;

}

.modal-body {
padding-top: 3px;
padding-bottom: 0;
}

.table.table_modal{
margin-left: -15px;
margin-right: -15px;
width: 110%;

}

.modal-footer {

padding: 0;
border-top: 0;
padding-right: 0.75em;

}

ul {
list-style-type: none;
}

li {
display: inline-block;
}

.proimgCheck[type="checkbox"][id^="cb"] {
display: none;
}
.proimgLabel {
border: 1px solid #fff;
padding: 10px;
display: block;
position: relative;
margin: 10px;
cursor: pointer;
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.proimgLabel::before {
background-color: white;
color: white;
content: " ";
display: block;
border-radius: 50%;
border: 1px solid grey;
position: absolute;
top: -5px;
left: -5px;
width: 25px;
height: 25px;
text-align: center;
line-height: 28px;
transition-duration: 0.4s;
transform: scale(0);
}

.proimgLabel img {
height: 75px;
width: 100px;
transition-duration: 0.2s;
transform-origin: 50% 50%;
}

.proimgCheck:checked+label {
border-color: #ddd;
}

.proimgCheck:checked+label::before {
content: "✓";
background-color: $primary;
transform: scale(1);
}

.proimgCheck:checked+label img {
transform: scale(0.9);
box-shadow: 0 0 5px #333;
z-index: -1;
}

//---------------------------------------SCROLLBAR---------------------------------------------


::-webkit-scrollbar {
width: 9px;
height: 9px;
}
::-webkit-scrollbar-button {
width: 0px;
height: 0px;
}
::-webkit-scrollbar-thumb {
background: #e1e1e1;
border: 0px none #ffffff;
border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
background: #4a4a4a;
}
::-webkit-scrollbar-track {
background: #666666;
border: 0px none #ffffff;
border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
background: #666666;
}
::-webkit-scrollbar-track:active {
background: #ffffff;
}
::-webkit-scrollbar-corner {
background: transparent;
}

//---------------------------------------------------------------------------

.owl-theme .owl-nav [class*=owl-] i{
padding-right: 3px;
padding-left: 5px !important;

}

.owl-theme .owl-nav [class*=owl-]{
border-radius: 100%!important;
}

//---------------------------**********************************----------------------

