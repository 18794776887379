@media (min-width: 768px) {
    .villa-img-pro-xl{
        height: 555px;
        width: 555px;
    }

    .villa-img-pro-md{
        height: 230px;
        width: 100%;
    }

    .villa-img-pro-sm{
        height: 309px;
        width: 100%;
    }
    .villa-search-button{
        left: 50%;
        position: absolute;
    }
}

@media (min-width: 768px) {
    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 31.93%;
    }
}

@media (max-width: 991.98px) {
    .site-navbar {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .villa-proDer-my{
        margin-top: 6rem!important;
    }

}

@media (max-width: 991.98px) {
    .site-navbar .site-logo {
        float: left;
        position: relative; }

    #carousel-lancamentos{
        .owl-carousel .owl-nav button.owl-next{
            display: none;
        }
    }
}

@media (max-width: 1390.98px) {

    #carousel-lancamentos{

        .owl-theme .owl-dots, .owl-theme .owl-nav{
            margin-top:0!important;

        }
        .owl-theme .owl-nav{
            margin-top: 0!important;
        }
    }
}


//*/--- IMG DO SIMULADOR ---

@media (min-width: 1200.98px) {


    .ico_mob{
        display: none!important;
    }

    .sectionSimulador {
        margin-right: -16px;
        margin-left: 71px;
    }

    .imgSimulador {
        margin-left: -102px;
    }

    .img-fluid.imgEspecificadorVilla, .img-fluid.imgSimulador {
        max-width: 111%;
    }

    .especificadorVilla{
        margin-right: 71px;
        margin-left: -16px;
    }

    .imgEspecificadorVilla {
        margin-left: 21px;

    }

}

//--------------------------------------------------------NAV----------------------------------------

@media (min-width: 1400.98px) {
    .site-navbar .site-navigation .site-menu > li > a{
        font-size: 16px;
    }

    .container-mod{
        max-width: 1400px!important;
    }
}

//--------------------------------------------------- MENU LISTA -----------------------------------------------------

@media (max-width: 1400.0px) {
    .site-navbar .site-navigation .site-menu > li > a{
        font-size: 13px;
    }
}

//---------------------------------- Conteudo interno Simulador de Ambientes || Especificador Villa ------------------------------------------
@media (min-width: 771.98px) {
    .conteudoRes{
        max-width: 45%;
        margin-right: 2em;
    }

    .conteudoRes .pl-4{
        width: 92%;
    }

    .titulosPrincipais{
        margin-top: 24%;
    }

}
@media (max-width: 768.0px) {

    #carousel-lancamentos{

        .owl-theme .owl-dots, .owl-theme .owl-nav{
            margin-top:15px!important;

        }
    }

    .owl-nav, .gdIco{ // remove botão para dispositivos pequenos
        display: none;
    }

    .villa-colecao-nome{
        display: block !important;
    }

}

//------------------------------------------------TITULOS DAS PAGINAS ---------------------------------

@media (min-width: 150px) {
    .cd-words-wrapper{
        font-size: 30px;
    }
    .mgtopo{
        margin-top: 60%;
    }
    h1.img__description{
        font-size: 1.9rem;
    }

    .descrImg-cole h1{

        font-size: 1.5rem;
        color: white;
    }

    p{
        font-size: 18px;
    }

     .tituloFundo{

        font-size: 3.0em;
        margin-top: 100px;

    }
    .desLinhaBerlin{
        font-size: 5.0em;
    }

    .desColecao{
        font-size: 2.5em;
        line-height: 1em;
        font-weight: 400;
    }
    .px-lateral{
        margin-left: 5%;
        margin-right: 5%;
        width: 100%;
    }

}

/*// Small devices (landscape phones, less than 768px)*/
@media (min-width: 767.98px) {
    .cd-words-wrapper {
        font-size: 50px;
    }
    .mgtopo{
        margin-top: 25%
    }
    h1.img__description{
        font-size: 1.9rem;
    }
    .miniIco{
        display: none;
    }
    .h1{
        font-size: 2.1rem;
    }
    .descrImg-cole h1{

        font-size: 1.5rem;
    }

    p{
        font-size: 12px;
    }

    .vejatambem{

        padding-left: -15;
        padding-right: -15;



    }

     .tituloFundo{

        margin-top: 100px;

    }

    .px-lateral-titulo{
        padding-left: 6rem!important;
        padding-right: 6rem !important;
    }
}

/*// Medium devices (tablets, less than 992px)*/
@media (min-width: 991.98px) {
    .cd-words-wrapper {
        font-size: 70px;
    }
    .mgtopo{
        margin-top: 13%;
    }
    h1.img__description{
        font-size: 2.0rem;
    }
    .descrImg-cole h1{

        font-size: 2.0rem;
    }

    p{
        font-size: 14px;
    }
    .tituloFundo{

        font-size: 6em;
        margin-top: 100px;
        padding-left: 15px;

    }

    .descriFundo{
        padding-left: 36px;
    }

    .desLinhaBerlin{
        font-size: 6.8em;
    }

    .desColecao{
        font-size: 6.8em;
        line-height: 1em;
        font-weight: 500;
    }

}

/*// Large devices (desktops, less than 1200px)*/
@media (min-width: 1199.98px) {
    .cd-words-wrapper {
        font-size: 80px;
    }
    .mgtopo{
        margin-top: 13%;
    }

    .mgtopo.porce{
        margin-top: 10%
    }

    h1.img__description{
        font-size: 2.0rem;
    }

    .descrImg-cole h1{

        font-size: 2.0rem;
        color: rgba(255, 255, 255, 0.90);
    }

    .unidadeSC.col-md-3{     //------------- UNIDADE SC

        max-width: 22%;
    }

    p{
        font-size: 16px;
    }
   .tituloFundo{

        margin-top: 100px;

    }
    .px-lateral{
        margin-left: 6%;
        margin-right: 6%;
    }
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (min-width: 1399.98px) {
    .cd-words-wrapper {
        font-size: 100px;
    }
    .mgtopo{
        margin-top: 15%;
    }
    h1.img__description{
        font-size: 2.03rem;
    }

    p{
        font-size: 18px;

    }

     .tituloFundo{

        font-size:7.5em;

    }

    .modal.fade{

        margin-top: 10%;

    }

    .px-lateral-titulo{
        padding-left: 4rem!important;
        padding-right: 4rem !important;
    }
}


